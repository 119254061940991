body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  max-height: 8px;
}

/* ::-webkit-scrollbar-track {
  background: rgba(21, 30, 61, 0.3);
  height: 100px;
}

::-webkit-scrollbar-thumb {
  background: #de6d8c;
  border-radius: 20px;
} */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #96a6bf, #63738c);
  box-shadow: inset 0 0 1px 1px #5c6670;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eee;
  box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(59, 59, 59, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(left, #8391a6, #536175);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.style_customer_name {
  display: flex;
  gap: 15px;
}
.buyer_note_close_btn{
  position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid #80808078;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
}
.buyer_note_btn{
  min-width: 70%;
}
.buyer_note_modal_btn {
  margin-top: 15px !important;
  display: flex
;
  justify-content: space-around;
}
.buyer_note_modal_btn_top {
 
  display: flex
;
  justify-content: space-around;
  margin-bottom: 15px !important;
}
.buyer_note_textarea{
  width: 100%;
  padding: 5px;
  margin: 20px 0px;
}